@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("manrope");
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@400;600;700;800&display=swap");

html {
  font-family: "Manrope",sans-serif;
}

article {
  width: 900px;
  margin: auto;
  padding: 0px 50px;
  padding-bottom: 32px;
  padding-top: 44px;
  box-shadow: 0px 2px 10px rgba(0, 80, 125, 0.08);
  border-radius: 8px;
}

article h1 {
  font-size: 24px;
  line-height: 36px;
}

article h2 {
  font-size: 16px;
  line-height: 26px;
  margin-top: 20px;
}

article h3 {
  margin-top: 20px;
  font-size: 14px;
  line-height: 22px;
}

article p {
  margin-top: 12px;
  font-size: 14px;
  line-height: 22px;
}

@media (max-width: 1024px) {
  article {
    margin-top: 20px;
    padding-top: 0px;
    padding: 0px 24px;
    width: 100%;
    padding-bottom: 32px;
  }
  article h2 {
    margin-top: 12px;
  }
  article h3 {
    margin-top: 12px;
  }
  article p {
    margin-top: 8px;
  }
}
